import React from "react";

export enum DialogName {
  Contact = "Contact",
  MintTerms = "MintTerms",
}

type DialogContextState = {
  currentDialog: DialogName | null;
  setCurrentDialog: (currentDialog: DialogName | null) => void;
};

export const DialogContext = React.createContext({
  currentDialog: null,
  setCurrentDialog: (currentDialog: DialogName | null) => undefined,
} as DialogContextState);

/* eslint-disable @next/next/no-html-link-for-pages */

import React from "react";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import Image from "next/image";
import icon from "../assets/images/logo.png";
import { Link as Scroll } from "react-scroll";
import { DialogContext, DialogName } from "../../context/DialogContext";
import { useRouter } from "next/router";
import Link from "next/link";
import Headroom from "react-headroom";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAnchor } from "../../hooks/useAnchor";
import dynamic from "next/dynamic";
import getConfig from "next/config";
// import { TopBanner } from "./TopBanner";

const { publicRuntimeConfig } = getConfig();

const Footer = dynamic(() => import("../../components/Footer"));

const navigation = [
  { name: "Features", to: "anchor-features" },
  { name: "$MAX", to: "anchor-token" },
  { name: "Roadmap", to: "anchor-roadmap" },
  // { name: "Team", to: "anchor-team" },
  { name: "Press", to: "anchor-blog" },
  { name: "FAQ", to: "anchor-faq" },
];

const anchors = navigation.map((n) => n.to);

export const Layout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const router = useRouter();
  const { setCurrentDialog } = React.useContext(DialogContext);
  const isMainPage = router.route === "/";

  useAnchor(!isMainPage, anchors);

  return (
    <>
      <Headroom>
        {/* {publicRuntimeConfig.feature.mint && <TopBanner />} */}
        <div className="bg-maxima-background">
          <Popover as="header" className="relative">
            <div className="py-6">
              <nav
                className="relative flex items-center justify-between px-4 mx-auto max-w-7xl sm:px-6"
                aria-label="Global"
              >
                <div className="flex justify-between flex-1">
                  <div className="flex items-center justify-between w-full lg:w-auto">
                    {router.pathname !== "/" ? (
                      <a href="/">
                        <Image
                          src={icon}
                          alt="Maxima Icon"
                          width={180}
                          height={40}
                          placeholder="blur"
                          priority
                        />
                      </a>
                    ) : (
                      <Scroll
                        href="anchor-topBanner"
                        to="anchor-topBanner"
                        spy
                        smooth
                        offset={-50}
                        duration={500}
                      >
                        <Image
                          src={icon}
                          alt="Maxima Icon"
                          width={180}
                          height={40}
                          placeholder="blur"
                          priority
                        />
                      </Scroll>
                    )}

                    <div className="flex items-center -mr-2 lg:hidden">
                      <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-transparent rounded-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                        <span className="sr-only">Open main menu</span>
                        <MenuIcon className="w-6 h-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="hidden ml-4 space-x-8 lg:space-x-10 lg:flex lg:items-center">
                    {navigation.map((item) => {
                      if (!isMainPage) {
                        return (
                          <Link href={`/#${item.to}`} passHref key={item.name}>
                            <span className="font-medium text-gray-300 cursor-pointer hover:text-maxima-secondary-400">
                              {item.name}
                            </span>
                          </Link>
                        );
                      }

                      return (
                        <Scroll
                          key={item.name}
                          href={item.to}
                          className="font-medium text-gray-300 hover:text-maxima-secondary-400"
                          activeClass="text-maxima-secondary-400"
                          to={item.to}
                          spy={true}
                          smooth={true}
                          offset={-50}
                          duration={500}
                        >
                          {item.name}
                        </Scroll>
                      );
                    })}
                    <button
                      onClick={() => setCurrentDialog(DialogName.Contact)}
                      className="font-medium text-gray-300 cursor-pointer hover:text-maxima-secondary-400"
                    >
                      Contact
                    </button>
                    <a
                      href="https://twitter.com/MaximaHorse/"
                      className="text-gray-400 hover:text-maxima-secondary"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="sr-only">Twitter</span>
                      <svg
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        className="w-5 h-5"
                        aria-hidden="true"
                      >
                        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                      </svg>
                    </a>
                    <a
                      href="https://discord.gg/maxima"
                      className="text-gray-400 hover:text-maxima-secondary"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="sr-only">Telegram</span>
                      <FontAwesomeIcon
                        className="w-5 h-5 hover:text-maxima-secondary"
                        icon={faDiscord}
                        size={"1x"}
                      />
                    </a>
                    {publicRuntimeConfig.feature.mint && (
                      <Scroll
                        href="anchor-mint"
                        className="p-3 font-medium bg-transparent border rounded-md text-maxima-secondary border-maxima-secondary hover:border-yellow-500 hover:text-yellow-500"
                        activeClass="text-maxima-secondary-400"
                        to="anchor-mint"
                        spy={true}
                        smooth={true}
                        offset={-50}
                        duration={500}
                      >
                        Mint Ticket
                      </Scroll>
                    )}
                  </div>
                </div>
              </nav>
            </div>

            <Transition
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute inset-x-0 top-0 z-10 p-3 transition origin-top transform lg:hidden"
              >
                <div className="overflow-hidden bg-gray-800 rounded-lg shadow-md ring-1 ring-black ring-opacity-5">
                  <div className="flex items-center justify-between px-5 pt-4">
                    <div>
                      <Image
                        src={icon}
                        alt="Maxima Icon"
                        width={180}
                        height={40}
                        placeholder="blur"
                        priority
                      />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-gray-800 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-maxima-secondary-400">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="w-6 h-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="pt-6 pb-6">
                    <div className="px-2 space-y-1">
                      {router.pathname == "/" ? (
                        navigation.map((item) => {
                          return (
                            <Scroll
                              key={item.name}
                              href="/"
                              to={item.to}
                              spy={true}
                              smooth={true}
                              offset={-30}
                              duration={2500}
                              className="block px-3 py-2 text-base font-medium text-gray-300 rounded-md hover:bg-gray-500"
                            >
                              {item.name}
                            </Scroll>
                          );
                        })
                      ) : (
                        <div className="block px-3 py-2 text-base font-medium text-gray-300 rounded-md hover:bg-gray-500">
                          <a key="Home" href="/">
                            Home
                          </a>
                        </div>
                      )}
                      <div className="block px-3 py-2 text-base font-medium text-gray-300 rounded-md hover:bg-gray-500">
                        <Link key="Privacy" href="/privacy">
                          Privacy
                        </Link>
                      </div>
                      <div className="block px-3 py-2 text-base font-medium text-gray-300 rounded-md hover:bg-gray-500">
                        <Link key="Terms" href="/terms-and-conditions">
                          Terms and Conditions
                        </Link>
                      </div>
                      <button
                        onClick={() => setCurrentDialog(DialogName.Contact)}
                        className="px-3 py-2 font-medium text-gray-300 cursor-pointer hover:text-maxima-secondary-400"
                      >
                        Contact
                      </button>
                      <div className="flex items-center justify-center pt-12 space-x-16">
                        <a
                          href="https://twitter.com/MaximaHorse/"
                          className="text-gray-400 hover:text-maxima-secondary"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="sr-only">Twitter</span>
                          <svg
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            className="w-5 h-5"
                            aria-hidden="true"
                          >
                            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                          </svg>
                        </a>
                        <a
                          href="https://discord.gg/maxima"
                          className="text-gray-400 hover:text-maxima-secondary"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="sr-only">Telegram</span>
                          <FontAwesomeIcon
                            className="w-5 h-5 hover:text-maxima-secondary"
                            icon={faDiscord}
                            size={"1x"}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        </div>
      </Headroom>
      <main className="bg-maxima-background">{children}</main>
      <Footer />
    </>
  );
};

import "tailwindcss/tailwind.css";
import React from "react";
import { useRouter } from "next/router";
import { QueryClient, QueryClientProvider } from "react-query";
import { Layout } from "../components/common/Layout";
import { ContactDialog } from "../components/contact/ContactDialog";
import { DialogContext, DialogName } from "../context/DialogContext";
import { Toaster } from "react-hot-toast";
import * as gtag from "../services/gtag";
import { DefaultSeo } from "next-seo";
import type { AppProps /*, AppContext */ } from "next/app";
import { ThirdwebWeb3Provider } from "@3rdweb/hooks";
import "regenerator-runtime/runtime";
import { withPasswordProtect } from "@storyofams/next-password-protect";
import NextNProgress from "nextjs-progressbar";

// Create a client
const queryClient = new QueryClient();

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const [currentDialog, setCurrentDialog] = React.useState<DialogName | null>(
    null
  );
  const supportedChainIds = [1, 4];
  const connectors = {
    injected: {},
  };

  React.useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageview(url);

      // @ts-ignore
      if (window?.analytics) {
        // @ts-ignore
        window?.analytics.page(url);
      }
    };
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <ThirdwebWeb3Provider
      supportedChainIds={supportedChainIds}
      connectors={connectors}
    >
      <DefaultSeo
        title="Maxima Horse Syndication"
        description="The World First Horse Ownership by Non-Fungible Token (NFT) ERC-1155"
        canonical="https://www.maxima.horse"
        openGraph={{
          url: "https://www.maxima.horse",
          title: "Maxima NFT Horse Syndication",
          description:
            "The World First Horse Ownership by Non-Fungible Token (NFT) ERC-1155",
          images: [
            {
              url: "https://www.maxima.horse/logo.png",
              width: 800,
              height: 600,
              alt: "Maxima Logo",
              type: "image/jpeg",
            },
          ],
          site_name: "Maxima NFT Racehorse Marketplace",
        }}
        twitter={{
          handle: "@MaximaHorse",
          site: "@MaximaHorse",
          cardType: "summary_large_image",
        }}
        facebook={{
          appId: "861106798100430",
        }}
      />
      <Toaster position="bottom-center" />
      <QueryClientProvider client={queryClient}>
        <DialogContext.Provider value={{ currentDialog, setCurrentDialog }}>
          <Layout>
            <NextNProgress color="#4DE4C7" />
            <Component {...pageProps} />
          </Layout>
          <ContactDialog
            isOpen={currentDialog === DialogName.Contact}
            onClose={() => setCurrentDialog(null)}
          />
        </DialogContext.Provider>
      </QueryClientProvider>
    </ThirdwebWeb3Provider>
  );
}

export default process.env.PASSWORD_PROTECT
  ? withPasswordProtect(MyApp)
  : MyApp;

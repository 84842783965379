import React from "react";
import { useForm } from "react-hook-form";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";
import * as gtag from "../../services/gtag";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

type Data = {
  fullname: string;
  email: string;
  description?: string;
};

enum RoleSelector {
  newsletter = "newsletter",
  investor = "investor",
  whitelist = "whitelist",
  partnership = "partnership",
}

export const ContactDialog: React.FC<Props> = (props) => {
  const [visitorRole, setVisitorRole] = React.useState<RoleSelector>(
    RoleSelector.newsletter
  );

  const onReset = () => {
    setVisitorRole(RoleSelector.newsletter);
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm({
    defaultValues: {
      fullname: "",
      email: "",
      description: "",
    },
  });

  const mutation = useMutation(async (data: Data) => {
    const payload = {
      ...data,
      role: visitorRole,
    };

    const res = await fetch("/api/subscribe", {
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((e) => {
        console.error(e);
        return toast.error("Subscibe Error, Please contact support");
      });

    toast("Thank you for subscribe. Stay in touch!", {
      icon: "👏",
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });

    // global.analytics.track("Subscribed", {
    //   payload,
    // });

    gtag.event({
      action: "Subscribed",
      category: "Contact",
      label: payload.email,
      value: payload,
    });

    props.onClose();
    reset();
    onReset();

    return res;
  });

  return (
    <Transition.Root show={props.isOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-30 overflow-hidden "
        onClose={props.onClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 shadow-inner-xl">
            <Transition.Child
              as={React.Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <form
                onSubmit={handleSubmit(mutation.mutate as any)}
                className="w-screen max-w-md"
              >
                <div className="flex flex-col h-full shadow-xl">
                  <div className="flex flex-col flex-1 min-h-0 overflow-y-scroll">
                    <div className="px-4 py-6 bg-maxima-secondary-400 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">
                          Join Maxima community
                        </Dialog.Title>
                        <div className="flex items-center ml-3">
                          <button
                            type="button"
                            className="text-indigo-200 rounded-md bg-maxima-secondary-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={props.onClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="w-6 h-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-black">
                          Secure you priority access to future drops with
                          staking opportunities, partner offers, updates on our
                          roadmap, and reserve your horses.
                        </p>
                      </div>
                    </div>
                    <div className="relative flex-1 px-6 bg-gray-800">
                      <div className="h-full" aria-hidden="true">
                        <div className="py-8 space-y-6">
                          <div>
                            <label
                              htmlFor="fullname"
                              className="block font-medium text-gray-100 text-md"
                            >
                              Your name*
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                id="fullname"
                                className="block w-full p-2 border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500"
                                {...register("fullname", {
                                  required: true,
                                  minLength: 1,
                                  maxLength: 250,
                                })}
                              />
                            </div>
                            {errors.fullname && (
                              <div className="py-2 text-red-400">
                                Please let us know who you are
                              </div>
                            )}
                          </div>
                          <div>
                            <label
                              htmlFor="email"
                              className="block font-medium text-gray-100 text-md"
                            >
                              Your email*
                            </label>
                            <div className="mt-2">
                              <input
                                type="email"
                                id="email"
                                {...register("email", {
                                  required: true,
                                  minLength: 5,
                                  maxLength: 250,
                                  pattern: /^\S+@\S+$/i,
                                })}
                                className="block w-full p-2 border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500"
                              />
                            </div>
                            {errors.email && (
                              <div className="py-2 text-red-400">
                                Seems your email is incorrect
                              </div>
                            )}
                          </div>

                          <div>
                            <label
                              htmlFor="description"
                              className="block font-medium text-gray-100 text-md"
                            >
                              What do you want to know about us? (optional)
                            </label>
                            <div className="mt-2">
                              <textarea
                                id="description"
                                rows={4}
                                {...register("description")}
                                className="block w-full p-2 border border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500"
                                defaultValue={""}
                              />
                            </div>
                          </div>
                          <div className="border-t border-gray-500">
                            <fieldset className="my-4">
                              <div>
                                <legend className="text-base font-medium text-white">
                                  The best way to describe your help
                                </legend>
                                <p className="text-sm text-gray-400">
                                  You may receive exclusive contact/offers from
                                  us.
                                </p>
                              </div>
                              <div className="mt-4 space-y-4">
                                <div className="flex items-center">
                                  <input
                                    id="field-newsletter"
                                    name="contribution"
                                    type="radio"
                                    className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                    value={RoleSelector.newsletter}
                                    checked={
                                      visitorRole === RoleSelector.newsletter
                                    }
                                    onChange={() =>
                                      setVisitorRole(RoleSelector.newsletter)
                                    }
                                  />
                                  <label
                                    htmlFor="field-newsletter"
                                    className="block ml-3 text-sm font-medium text-gray-300"
                                  >
                                    Latest Update from Maxima
                                  </label>
                                </div>
                                {/* <div className="flex items-center">
                                  <input
                                    id="field-whitelist"
                                    name="contribution"
                                    type="radio"
                                    className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                    value={RoleSelector.whitelist}
                                    checked={
                                      visitorRole === RoleSelector.whitelist
                                    }
                                    onChange={() =>
                                      setVisitorRole(RoleSelector.whitelist)
                                    }
                                  />
                                  <label
                                    htmlFor="field-whitelist"
                                    className="block ml-3 text-sm font-medium text-gray-300"
                                  >
                                    Private/Pre Sale Whitelist (Early Access)
                                  </label>
                                </div> */}
                                <div className="flex items-center">
                                  <input
                                    id="field-investor"
                                    name="contribution"
                                    type="radio"
                                    className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                    value={RoleSelector.investor}
                                    checked={
                                      visitorRole === RoleSelector.investor
                                    }
                                    onChange={() =>
                                      setVisitorRole(RoleSelector.investor)
                                    }
                                  />
                                  <label
                                    htmlFor="field-investor"
                                    className="block ml-3 text-sm font-medium text-gray-300"
                                  >
                                    Investor
                                  </label>
                                </div>
                                <div className="flex items-center">
                                  <input
                                    id="field-partnership"
                                    name="contribution"
                                    type="radio"
                                    className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                    value={RoleSelector.partnership}
                                    checked={
                                      visitorRole === RoleSelector.partnership
                                    }
                                    onChange={() =>
                                      setVisitorRole(RoleSelector.partnership)
                                    }
                                  />
                                  <label
                                    htmlFor="field-partnership"
                                    className="block ml-3 text-sm font-medium text-gray-300"
                                  >
                                    Partnership
                                  </label>
                                </div>
                              </div>
                            </fieldset>
                          </div>
                        </div>
                      </div>

                      {/* /End replace */}
                    </div>
                  </div>
                  <div className="flex justify-end flex-shrink-0 px-4 py-4 bg-gray-800">
                    <button
                      type="button"
                      className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={props.onClose}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      disabled={!isDirty || mutation.isLoading}
                      className={`${
                        (!isDirty || mutation.isLoading) &&
                        "disabled:opacity-50"
                      } inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-maxima-secondary-400 hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                    >
                      {mutation.isLoading ? `Submitting` : `Submit`}
                    </button>
                  </div>
                </div>
              </form>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

import React from "react";
import { scroller } from "react-scroll";
import { useRouter } from "next/router";

export const useAnchor = (isSubPage: boolean, anchors: Array<string>) => {
  const router = useRouter();

  // 1. Scroll to target section when there is a route change to the main page
  // 2. Remove the anchor when the page change is done
  React.useEffect(() => {
    if (isSubPage) {
      return;
    }

    anchors.forEach((anchor) => {
      if (router.asPath.includes(anchor)) {
        scroller.scrollTo(anchor, { smooth: false });
      }
    });

    const cleanUpHash = (url: string) => {
      if (router.asPath.includes(url)) {
        router.replace("/");
      }
    };

    router.events.on("routeChangeComplete", cleanUpHash);

    return () => {
      router.events.off("routeChangeComplete", cleanUpHash);
    };
  }, [router.route]);
};
